import React, { createContext, useContext, useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import axios from 'axios';

const Context = createContext();

export const ContextAPI = () => {
  return useContext(Context);
};

export const ContextProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  useEffect(() => {
   
    DataHandler();
    localStorage.setItem('Page', '1');
  }, []);

  const DataHandler = async (Param) => {
    if (!Param) {
      try {
        let { data } = await axios.get('https://gautamsolar.us/admin/news?NoOfNews=6&Page=1');
        setData(data.data);
      } catch (err) {
        setData([]);
        setToastMessage("We have no data");
        setShowToast(true);
      }
    } else {
      setData(Param);
    }
  };

  return (
    <Context.Provider value={{ DataHandler, setData, data }}>
      {children}
      <ToastContainer position="top-center" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Oops!</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Context.Provider>
  );
};
