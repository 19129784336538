import React, { useState } from 'react';
import backgroundImage from "../../assets/images/solarpower.jpg";
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap';
import { IoIosMail, IoIosCall, IoLogoWhatsapp } from 'react-icons/io';
import axios from 'axios';
import "../solarplants/SolarPlants.css";
import solar1 from "../../assets/images/scard1.webp";
import solar2 from "../../assets/images/scard2.jpg";
import solar3 from "../../assets/images/scard3.webp";
import solar9 from "../../assets/images/scard4.jpg";
import solar10 from "../../assets/images/adv2.jpg";
import solar11 from "../../assets/images/adv3.jpg";
import solar5 from "../../assets/images/scard5.jpg";
import solar6 from "../../assets/images/scard6.jpeg";
import solar7 from "../../assets/images/scard6.jpg";


const SolarPlants = () => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    option: '',
    sizeoption: ''

  });

  const validateForm = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('https://gautamsolar.us/submit-solarplant', formValues);


      if (response.status === 200) {
        alert('Form submitted successfully!');
      } else {
        alert('Error submitting form. Please try again later.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again later.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#00000087',
    backgroundBlendMode: 'multiply',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '600px',
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // opacity: "0.5"
  };
  const cardData = [
    {
      id: 1,
      image: solar1,
      heading: 'OPEN ACCESS',
      text: 'Suitable for Businesses with High Electricity Demand (More than 1 MW) looking to meet ESG Goals and reduce their electricity costs. Solar Power Plant is deployed Off-site in same state where electricity is consumed but in a different location than the office/ factory premises. Available in Captive & Group Captive mode.',

    },
    {
      id: 2,
      image: solar2,
      heading: 'GROUND-MOUNTED',
      text: 'Suitable for Businesses with Large Land Area within their campus looking to consume all power in captive mode. Get Lower electricity bills and take a positive step in Energy Transition.',

    },
    {
      id: 3,
      image: solar3,
      heading: 'ROOFTOP',
      text: 'Grid-connected Rooftop Solar Plants for Commercial & Industrial Enterprises to transform your Rooftops into a Source of Clean Energy and lower your power bills.',

    },

    // Repeat similar objects for other cards (2 to 9)
  ];
  // Advantage section


  const tabsData = [
    {
      title: 'Guaranteed Power Generation',
      content:
        'Solar Modules are the most critical component of any Solar Power Plant, which directly impacts power generation and reliability. Gautam Solar Modules are manufactured using A++ Raw Materials, Top of Line Machines, and using patented processes so that maximum power is generated for its customers.',
      image: solar9,
    },
    {
      title: 'Access to Latest Technology',
      content:
        'Solar Modules are rapidly changing technology with higher cell efficiencies being launched quickly and technology changing fast. By getting EPC done from a Solar Module Manufacturer, you get access to the Latest Technology. By procuring Modules & EPC Services from Gautam Solar, you can be assured of guaranteed Warranty.',
      image: solar10,
    },
    {
      title: 'Warranty Support for Solar Power Plant',
      content:
        'As Gautam Solar Modules are used in the solar power plant, we have our own Manufacturing and we do not have to coordinate with any other vendor for warranty support. This becomes especially relevant as Module technologies keep on changing very fast and warranty support for older technology modules may or may not be available with the EPC vendor. Also many inexperienced solar companies have not been able to honor warranties as their modules were not manufactured in India but procured from China.',
      image: solar11,
    },
  ];
  const [selectedImage, setSelectedImage] = useState(tabsData[0].image);

  const [clickedTabIndex, setClickedTabIndex] = useState(null);
  const handleTextClick = (index) => {
    setSelectedImage(tabsData[index].image);
    setClickedTabIndex(index);
  }
  const projectData = [
    {
      id: 1,
      image: solar5,
      heading: 'Bhopal Airport (Raja Bhoj Airport)',
    },
    {
      id: 2,
      image: solar6,
      heading: '70 MW in North India',
    },
    {
      id: 3,
      image: solar7,
      heading: '10 MW in Uttarkashi',
    }
  ];


  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="d29e141"
        data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        style={{ ...sectionStyle }}
      >
        <div className="elementor-background-overlay"></div>
        <Container className="elementor-container elementor-column-gap-no">
          <Row className="elementor-row">
            <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                    data-id="b12b60d"
                    data-element_type="widget"
                    data-widget_type="modina_section_title.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="block-contents text-center">
                        <div className="section-title">
                          <h5 className="text-center"></h5>
                          <span></span>
                          <h2>
                            Gautam Solar<br></br>
                            Your Energy Transition Partner</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

      </section>
      <section className="py-5 text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-12 col-md-12 mx-auto text-right">
            <h1 className="fw-bold" style={{ position: 'relative', fontSize: "40px" }}>
              <span style={{ position: 'absolute', top: '-10px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
              <span style={{ fontWeight: 'bold', color: '#a20000' }}>Open Access</span> | Ground Mounted | <span style={{ fontWeight: 'bold', color: '#a20000' }}>Rooftop Solar Power Plants</span>
            </h1>

            <p style={{ fontSize: "18px" }} className="custom-paragraph lead text-body-secondary">
              Gautam Solar is India’s Top 10 Solar Company, delivering Solar Power plant solutions to Commercial & Industrial businesses. We are committed to accelerating <br></br> Decarbonization across the corporate sector.<br></br>

              With 25+ yrs of experience in Solar Industry, Gautam Solar gives a unique advantage of being a vertically integrated company which is doing both manufacturing of Solar Modules in India and doing EPC for Solar Power Plants for Commercial & Industrial Enterprises.
            </p>




          </div>
        </div>
      </section>
      {/* // range of offering section */}
      <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '50px', paddingTop: '50px' }}>
        <section className="py-5 text-center container">
          <div className="row py-lg-2">
            <div className="col-lg-12 col-md-12 mx-auto text-right">
              <h1 className="fw-bold" style={{ position: 'relative', fontSize: "40px" }}>
                <span style={{ position: 'absolute', top: '-10px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
                Solar Energy Solutions For <span style={{ fontWeight: 'bold', color: '#a20000' }}>Commercial & Industrial</span>
              </h1>
            </div>
          </div>
        </section>
        <Container className='my-4' fluid>
          <Row xs={1} md={3} className="g-4 row-gutter">
            {cardData.map((card) => (
              <Col key={card.id} className="mb-3">
                <Card className="h-100">
                  <a href={card.link} target="_blank" rel="noopener noreferrer">
                    <Card.Img
                      variant="top"
                      src={card.image}
                      className="card-img"
                      style={{ maxHeight: '200px', objectFit: 'contain', margin: 'auto' }}
                    />
                  </a>
                  <Card.Body className="d-flex flex-column justify-content-between text-center">
                    <div>
                      <Card.Title style={{ minHeight: '33px' }}>{card.heading}</Card.Title>
                      <Card.Text style={{ color: "#a20000" }}>{card.text}</Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      {/* // advantage section */}
      <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '20px', paddingTop: '30px' }}>
        <section className="py-2 text-center container">
          <div className="row py-lg-2">
            <div className="col-lg-12 col-md-8 mx-auto text-right">
              <h1 className="fw-bold" style={{ position: 'relative', fontSize: "60px" }}>
                <span style={{ position: 'absolute', top: '-10px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
                Gautam Solar <span style={{ fontWeight: 'bold', color: '#a20000' }}>Advantage</span>
              </h1>
            </div>
          </div>
        </section>
        <Container className="my-4">
          <Row>
            <Col md={6} className="mb-3">
              <Card className="h-100">
                <Card.Body className="d-flex flex-column justify-content-between text-center">
                  <div>
                    {tabsData.map((tab, index) => (
                      <div key={index} className="mb-3" onClick={() => handleTextClick(index)}>
                        <Card.Title style={{ minHeight: '33px' }}>{tab.title}</Card.Title>
                        <Card.Text style={{ color: clickedTabIndex === index ? '#a20000' : 'black',color: clickedTabIndex === index ? '#a20000' : 'black' }}>{tab.content}</Card.Text>
                      </div>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} className="d-flex align-items-center justify-content-center">
              {selectedImage && <Card.Img style={{ maxHeight: "600px" }} variant="top" src={selectedImage} className="img-fluid card-img-1" />}
            </Col>
          </Row>
        </Container>

      </div>
      {/* Projects */}
      <div style={{ backgroundColor: '#f7f7f7', paddingBottom: '50px', paddingTop: '50px' }}>
        <section className="py-2 text-center container">
          <div className="row py-lg-2">
            <div className="col-lg-12 col-md-12 mx-auto text-right">
              <h1 className="fw-bold" style={{ position: 'relative', fontSize: "40px" }}>
                <span style={{ position: 'absolute', top: '-10px', left: '50%', transform: 'translateX(-50%)', borderBottom: '2px solid #faab04', width: '50px' }}></span>
                Projects With <span style={{ fontWeight: 'bold', color: '#a20000' }}>Gautam Solar Modules</span>
              </h1>
            </div>
          </div>
        </section>
        <Container className='my-2' fluid>
          <Row xs={1} md={3} className="g-4 row-gutter">
            {projectData.map((card) => (
              <Col key={card.id} className="mb-3">
                <Card className="h-100">
                  <a href={card.link} target="_blank" rel="noopener noreferrer">
                    <Card.Img
                      variant="top"
                      src={card.image}
                      className="card-img"
                      style={{ maxHeight: '200px', objectFit: 'contain', margin: 'auto' }}
                    />
                  </a>
                  <Card.Body className="d-flex flex-column justify-content-between text-center">
                    <div>
                      <Card.Title style={{ minHeight: '33px' }}>{card.heading}</Card.Title>

                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

      </div>
      {/* enqiry now section */}
      <Container fluid className="py-5">
        <div className="row py-5">
          <div className="col-lg-9 col-md-8 mx-auto text-center">
            <h2 className="fw-bold" style={{ position: 'relative', color: 'black' }}>
              <span style={{
                position: 'absolute',
                top: '-15px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderBottom: '2px solid #faab04',
                width: '50px',
              }}></span>
              Enquiry <span style={{ color: "#a20000" }}>Now </span>
            </h2>
          </div>
        </div>
        <Row className="py-4">
          <Col md={6} className="mb-3 mb-md-0">
            <Row className="mb-3 justify-content-between">
              <Col xs={12} sm={6} className="mb-3">
                <div className="contact-info">
                  <a href="mailto:info@gautamsolar.com" style={{ textDecoration: 'none', color: 'white' }}>
                    <IoIosMail size={40} />
                    <h1>Email</h1>
                    <p>info@gautamsolar.com</p>
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <div className="contact-info">
                  <a href="tel:18005320800" style={{ textDecoration: 'none', color: 'white' }}>
                    <IoIosCall size={40} />
                    <h1>Service Support</h1>
                    <p>1800 532 0800</p>
                  </a>
                </div>
              </Col>
            </Row>
            <Row className="mb-3 justify-content-between">
              <Col xs={12} sm={6} className="mb-3">
                <div className="contact-info">
                  <a href="tel:+919311797248" style={{ textDecoration: 'none', color: 'white' }}>
                    <IoIosCall size={40} />
                    <h1>Sales</h1>
                    <p>+91 93117 97248</p>
                  </a>
                </div>
              </Col>
              <Col xs={12} sm={6} className="mb-3">
                <div className="contact-info">
                  <a href="https://wa.me/919311797248" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
                    <IoLogoWhatsapp size={40} />
                    <h1>WhatsApp</h1>
                    <p>+91 93117 97248</p>
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={6}>
            <Container fluid className="px-md-5">
              {/* Adjust padding for medium screens and larger */}
              <Container className="dataform" style={{ backgroundColor: '#d8d8d8', padding: '15px', borderRadius: '20px' }}>
                <Form onSubmit={validateForm}>
                  <Row>
                    <Col xs={12} md={12}>
                      {/* Use xs={12} for full width on small screens, md={6} for half width on medium and larger screens */}
                      <Form.Group controlId="name">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Enter Your Name" value={formValues.name} onChange={handleInputChange} required style={{ color: '#a20000', borderColor: '#a20000' }} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="email">
                        <Form.Label>Email:</Form.Label>
                        <Form.Control type="email" name="email" placeholder="Enter Your Email" value={formValues.email} onChange={handleInputChange} required style={{ color: '#a20000', borderColor: '#a20000' }} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="phone">
                        <Form.Label>Mobile Number:</Form.Label>
                        <Form.Control type="text" name="phone" placeholder="Enter Your Phone" value={formValues.phone} onChange={handleInputChange} required style={{ color: '#a20000', borderColor: '#a20000' }} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={12}>
                      <Form.Group controlId="company">
                        <Form.Label>Company Name:</Form.Label>
                        <Form.Control type="text" name="company" placeholder="Enter Your Company Name" value={formValues.company} onChange={handleInputChange} required style={{ color: '#a20000', borderColor: '#a20000' }} />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="options">
                        <Form.Label>Wants to Put up:</Form.Label>
                        <Form.Control as="select" name="option" value={formValues.option} onChange={handleInputChange} required style={{ borderColor: '#a20000' }}>
                          <option disabled value="">Select</option>
                          <option>Solar Power Plants</option>
                          <option>Buy Solar Modules</option>
                          <option>Both</option>
                          <option>Other</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="sizeoptions">
                        <Form.Label>Project Size</Form.Label>
                        <Form.Control as="select" name="sizeoption" value={formValues.sizeoption} onChange={handleInputChange} required style={{ borderColor: '#a20000' }}>
                          <option disabled value="">Select</option>
                          <option>More than 10 MWp</option>
                          <option>1-10 MWp</option>
                          <option>300 kWp - 1MWp</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xs={12}>
                      {/* Full width button */}
                      <div className='py-3' style={{ textAlign: 'center' }}>
                        <Button style={{ backgroundColor: '#a20000', width: '30%' }} variant="primary" type="submit">
                          Submit Request
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </Container>

          </Col>


        </Row>
      </Container>

    </>
  );
};

export default SolarPlants;
