import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "./Navigation.css";
import logo from "../../assets/images/Logo-white.png";

function Navigation() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
});
const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary " id="rescstomcss_1">
      <div className="brandNav">
        <img src={logo} width={200} alt="logo" />
      </div>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav
          className="me-auto navigation"
          id="navbartop"
          style={{ fontSize: "18px", width: "100%" }}
        >
          <Link to="/" onClick={handleClick} className="nav-link">Home</Link>
          <Link to="/aboutus" onClick={handleClick} className="nav-link">About Us</Link>
          <div className="dropdown">
            <Link to="#pricing" className="dropdown-link nav-link">
              Solar Modules{" "}
              <span>
                <MdOutlineKeyboardArrowDown />
              </span>
            </Link>
            <div className="dropdown-content">
              <div className="dropdown-item" style={{ whiteSpace: 'pre-wrap' }}>
                <Link to="/topcon" onClick={handleClick} className="nav-link" style={{ fontSize: "16px" }}>
                  N-Type TOPCon Solar Modules
                </Link>
              </div>
              <hr />
              <div className="dropdown-item" style={{ whiteSpace: 'pre-wrap', textAlign: 'left' }}>
                <Link to="/mono"  onClick={handleClick} className="nav-link" style={{ fontSize: "16px" }}>
                  Mono PERC Solar Modules
                </Link>
              </div>
            </div>
          </div>
          <Link to="/solarplants" onClick={handleClick} className="nav-link">Solar Power Plant</Link>
          <Link to="/quality" onClick={handleClick} className="nav-link">Quality Certificate</Link>
          <Link to="/media" onClick={handleClick} className="nav-link">Media</Link>
          <Link to="/csr" onClick={handleClick} className="nav-link">CSR</Link>
          <Link to="/blog" onClick={handleClick} className="nav-link">Blog</Link>
          <Link to="/contactus" onClick={handleClick} className="nav-link">Contact Us</Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navigation;
