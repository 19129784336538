import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navigation from "./common/navigation/Navigation";
import Topheader from "./common/topheader/Topheader";
import Home from "./pages/home/Home";
import Blog from "./pages/blog/Blog";
import Footer from "./common/footer/Footer";
import ContactUS from "./pages/contactus/ContactUS";
import Social from "./common/social/Social";
import CSR from "./pages/csr/CSR";
import AboutUs from "./pages/aboutus/AboutUs";
import Media from "./pages/media/Media";
import SolarPlants from "./pages/solarplants/SolarPlants";
import TermCondion from "./component/tandc/TermCondiotion";
import TopConSolar from "./pages/topconsolar/TopConSolar";
import QualityCertificate from "./pages/qcertificate/QualityCertificate";
import MonoPerc from "./pages/MonoPerc/MonoPerc";
import ContactBox from "./component/enquiry/ContactBox";
import Policy from "./component/privacy/Policy";
import CookieConsent from "./common/cookieconsent/CookieConsent";

import Read from "./component/readmore2/Read";

// import ReadMor from "./component/readmore4/readmor";
function App() {
  return (
    <Router>
      <div >
        <div style={{ position: "sticky", top: 0, zIndex: "100" }}>
          <Topheader />
          <Navigation />
        </div>


        <Social />


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contactus" element={<ContactUS />} />
          <Route path="/csr" element={<CSR />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/media" element={<Media />} />
          <Route path="/solarplants" element={<SolarPlants />} />
          <Route path="/topcon" element={<TopConSolar />} />
          <Route path="/mono" element={<MonoPerc />} />
          <Route path="/terms" element={<TermCondion />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/quality" element={<QualityCertificate />} />
          <Route path="/contactbox" element={<ContactBox />} />
          
          <Route path="/read" element={<Read />} />
         

     
          {/* <Route path="/readmor" element={<ReadMor />} /> */}
          
        </Routes>
        {/* <MyContactForm/> */}

        <Footer />
        <CookieConsent />
      </div>
    </Router>
  );
}





export default App;
