import React, { useState } from 'react';
import { Row, Col, Button, Form, Image, Container } from 'react-bootstrap';
import { Parser } from 'html-to-react';


import img2 from "../../assets/images/card2.png";

const Read = () => {
    const [Card, setCard] = useState(JSON.parse(localStorage.getItem('CardData')) || {});
    const tags = [
        'Co-Development Model',
        'Construction',
        'Developers',
        'EPC',
        'Land Leasing',
        'Legal Interest',
        'Neighborhood Considerations',
        'Site Assessment',
        'Solar Construction',
        'Topography',
        'How solar installations can benefit real estate developers'
    ];

    const htmlContent = Card ? Card.Body : "";

    const parser = new Parser();
    const jsxContent = parser.parse(htmlContent);

    return (
        <>
            <section className='py-5' >
                <div style={{ padding: "10px" }}>
                    <Row className="justify-content-center" style={{ display: "flex", gap: "7px" }}>
                        <Col lg={8} style={{ padding: '30px', border: '1px solid grey' }}>
                            <div className="mb-4">
                                <Button style={{ border: '2px solid #a20000', backgroundColor: '#a20000', color: 'white', padding: '8px', fontSize: 'large' }}>{Card ? Card.Header : ""}</Button>
                            </div>
                            <Image src={Card ? Card.ImageURL : ""} alt="" fluid className="mb-4" />
                            <>
                                {jsxContent}
                            </>
                            <Container>
                                <h1 className="text-left mb-4">Tags</h1>
                                <Row xs={1} sm={2} md={3}>
                                    {tags.map((tag, index) => (
                                        <Col key={index} className="mb-3">
                                            <Button className="tag" style={{ backgroundColor: '#a20000', margin: '5px', width: '100%', height: '40px', padding: '0 15px', lineHeight: '40px' }}>{tag}</Button>
                                        </Col>
                                    ))}
                                </Row>
                            </Container>

                        </Col>
                        <Col lg={3} style={{ padding: '30px', border: '1px solid grey', maxWidth: "100%", height: "100%" }}>
                            <div className="mb-4">
                                <Form.Control type="text" placeholder="Search.." style={{ padding: '8px', fontSize: 'large' }} />
                            </div>
                            <h1 className="mb-4">Recent Posts</h1>
                            <p>How Solar Installations Can Benefit Real Estate Developers</p>
                            <p>Solar Construction: Need Of The Hour</p>

                        </Col>
                    </Row>
                </div>
            </section>
        </>
    );
};

export default Read;

// import React from 'react';
// import { Parser } from 'html-to-react';

// const Read = () => {
//     const htmlContent = `<p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">For real</span> <a href="https://www.google.com/search?q=codepen&rlz=1C1UEAD_enIN1101IN1101&oq=codepen&gs_lcrp=EgZjaHJvbWUyDAgAEEUYORixAxiABDIGCAEQRRhAMg4IAhBFGCcYOxiABBiKBTIHCAMQABiABDIKCAQQABixAxiABDIHCAUQABiABDIHCAYQABiABDIGCAcQBRhA0gEIMTU1MmowajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target="_self"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">estate developers</span></a> <span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">, now is the best time to install a solar energy system in their projects because solar rebates, tax credits, and incentives are at all-time highs. At the same time, a solar energy system may help them prepare for tomorrow’s specific difficulties while also saving money now.</span><br>&nbsp;</p>
//   <p style="text-align:left;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">Solar energy is one of the simplest and most cost-effective investments commercial real estate (CRE) developers can make. Investing in solar was once considered expensive and cumbersome by property owners and managers, who believed the effort was not worth the financial return, but that is no longer true. Installing a solar photovoltaic (PV) array is one of the most cost-effective strategies to reduce utility expenses, increase rents, and reduce common area maintenance (CAM) reimbursements.</span><br>&nbsp;</p>
//   <p style="text-align:left;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">Many businesses have agreed to get all of their energy from renewable sources. Starbucks, Kohl’s, Apple, the National Hockey League, and Intel are among the companies that have purchased enough sustainable energy to counterbalance or run their whole operations. However, real estate developers who sell or lease their properties, on the other hand, have been more hesitant to include rooftop solar in their projects as there hasn’t been much incentive because their renters usually pay the power bills.</span></p>
//   <p style="text-align:start;"></p>
//   <h1 style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(162,0,0);font-size: 32px;font-family: Verdana;">“Solar energy systems are being used by real estate developers to keep renters, boost rents, and even save money for companies and individuals.”</span><br><br><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 1.5rem;font-family: Verdana;"><strong>Options</strong></span></h1>
//   <ul>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">Solar energy could previously only be used in communal power spaces on multi-tenant properties. This isn’t the case anymore. Individual renters can now profit from commercial solar systems, thanks to the recent implementation of “virtual net metering.” As a consequence, higher rents can be offered, and a tenant’s lease can be extended with assured energy savings baked in. It provides for fast ownership of the solar asset without a substantial financial outflow, and the lessor is frequently freed of equipment upkeep.</span><br>&nbsp;</li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">A power purchase agreement (PPA) is another common alternative in which a third-party developer, such as Sunpin Solar, owns, manages, and maintains the solar system while charging a fixed amount for power.</span></li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">Even if a building is not suitable for solar installation, it can benefit from wholesale solar energy sales from other large-scale solar installations. The cost of wholesale solar has decreased considerably, and the CRE sector is beginning.</span></li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">For CRE developers who are not willing to bear the costs of owning and managing installed solar, an Operating Lease is a popular option to take advantage of this through local community solar projects, municipal purchasing, and off-site solar procurement, where it was previously an unknown alternative.</span></li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">In today’s time, PV systems are seen by some entrepreneurial developers as a means to achieve green construction standards while also lowering vacancy rates and increasing lease rates. Most significantly, many people who are prepared to make this investment have figured out how to recoup their installation and administration expenses – they’ve figured out how to profit from PV.</span></li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">While net-zero energy buildings or buildings that generate all of their energy are a popular goal in the green building industry, there is also a space for buildings that use PV systems to satisfy only a portion of their energy demands.</span></li>
//     <li><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">In certain circumstances, developers may be able to use financial incentives to produce a small level of potential cash flow. Solar is a building enhancement that will increase income while significantly cutting running costs. Owners of certainly huge warehouses or similar buildings with an unobstructed rooftop may gain from leasing the space to absolutely such Solar firms that will install solar panels and create PV electricity.</span></li>
//   </ul>
//   <p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 18px;font-family: Verdana;"><strong>Benefit</strong></span></p>
//   <p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;"><strong>Attract a new tier of clientele.</strong>Tenants and residents who want to use renewable energy sources have plenty of options. Real estate developers may appeal to an untapped audience of clients who are prepared to pay extra for sustainable energy if their development can improve solar energy infrastructure. At the same time, by hedging against fluctuating energy prices, developers can safeguard renters and residents.</span><br></p>
//   <p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">Solar energy systems are being used by real estate developers to keep renters, boost rents, and even save money for companies and individuals. In either case, adding solar energy into the infrastructure is a valuable asset developers can add to their list of perks that renters will appreciate.</span><br>&nbsp;</p>
//   <p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;"><strong>Financial incentives and tax credits.</strong>Tax credits and other incentive schemes are available to help fund clean energy projects as the government attempts to assist the nascent solar industry. However, these initiatives may not continue much longer, so if developers want to optimize the return on their solar investment, now is the correct time. They may be able to use their financial incentives to achieve positive cash flow in some circumstances.</span></p>
//   <p style="text-align:start;"></p>
//   <p style="text-align:start;"><span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;"><strong>Minimal opportunity costs for solar land development.</strong></span> <span style="color: rgb(33,37,41);background-color: rgb(255,255,255);font-size: 16px;font-family: Verdana;">One of the advantages of solar adoption is the low opportunity costs for real estate developers because solar panels are typically installed on disused vacant land or in uninhabited locations such as roofs. They don’t have much to lose and a lot to gain by using a solar system to utilize their spare space.</span>&nbsp;</p>
//   );`

//     const parser = new Parser();
//     const jsxContent = parser.parse(htmlContent);
//     return <>{jsxContent}</>;
// };

// export default Read;
