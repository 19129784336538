import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import "../blog/Blog.css";
import { useState, useEffect } from 'react';
import img1 from "../../assets/images/card1.jpeg";
import img2 from "../../assets/images/card2.png";
import backgroundImage from "../../assets/images/slide2.jpeg";
import img3 from "../../assets/images/Untitled design (1).png";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Pagination from '../../component/pagination';
import { ContextAPI } from '../../ContextAPI/ContextAPI';

const Blog = () => {
    const sectionStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#a20000',
        backgroundBlendMode: 'multiply',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const {data} = ContextAPI()
    // const [Blog, setBlog] = useState(data)

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const response = await axios.get('https://gautamsolar.us/admin/news?NoOfNews=3&Page=1');
        //         console.log(response.data.data)
        //         setBlog(response.data.data);

        //     } catch (error) {
        //         console.error('Error fetching data:', error);
        //     }
        // };
        // fetchData();

        return () => {
            // Cleanup function if needed
        };
    }, [])
    console.log(data.length)
    /* Assuming dateStr is the fetched date string **/
    const formatDate = (dateStr) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateStr).toLocaleDateString('en-US', options);
        return formattedDate;
    };


   
    return (
        <>
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
                data-id="d29e141"
                data-element_type="section"
                data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
                style={{ ...sectionStyle }}
            >
                <div className="elementor-background-overlay"></div>
                <Container className="elementor-container elementor-column-gap-no">
                    <Row className="elementor-row">
                        <Col className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f" data-id="0a1c64f" data-element_type="column">
                            <div className="elementor-column-wrap elementor-element-populated">
                                <div className="elementor-widget-wrap">
                                    <div
                                        className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                                        data-id="b12b60d"
                                        data-element_type="widget"
                                        data-widget_type="modina_section_title.default"
                                    >
                                        <div className="elementor-widget-container">
                                            <div className="block-contents text-center">
                                                <div className="section-title">
                                                    <h5 className="text-center"></h5>
                                                    <span></span>
                                                    <h2>
                                                        Blogs</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Container className="mt-5">
                <Row xs={1} md={2} lg={3} className="g-4">
                    {data.length ? data.map(blog => (
                        <Col key={blog.UUID} className="mb-4">
                            <Card className="h-100">
                                <Card.Img variant="top" src={blog.ImageURL} className="img-fluid" />
                                <Card.Body>
                                    <Link to={blog.titleLink} className="card-link" />
                                    <Card.Title>{blog.Header}</Card.Title>
                                    <Link to={'/read'} onClick={() => {
                                        localStorage.setItem('CardData', JSON.stringify(blog))
                                    }} className="elementor-post__read-more" tabIndex="-1">Read More »</Link>

                                </Card.Body>
                                <Card.Footer>
                                    <div className="elementor-post__meta-data">
                                        <span className="elementor-post-date">{formatDate(blog.CreatedOn)}</span>
                                    </div>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )) : ""}
                </Row>
                <Pagination/>
                
            </Container>
            
            
        </>
    );
};

export default Blog;
