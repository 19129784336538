import React, { useState, useRef } from "react";
import { Container, FloatingLabel } from "react-bootstrap";
import axios from 'axios';
import { Link as RouterLink } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Modal } from "react-bootstrap";
// import emailjs from "@emailjs/browser";
import getinTouch from "../../assets/images/address-1.png";
import tick from "../../assets/images/Right Tick Icon-1.png";
import sender from "../../assets/images/back-arrow.png";
import "../contactus/ContactUs.css";
import backgroundImage from "../../assets/images/slide2.jpeg";
const ContactUS = () => {
  const sectionStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#a20000",
    backgroundBlendMode: "multiply",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };
  const [checkboxValues, setCheckboxValues] = useState({
    moreThan500: false,
    from100To500: false,
    from10To100: false,
    from1To10: false,
    lessThan1: false,
    somethingElse: false,
  });

  const formRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
  };
  const sendEmail = async (e) => {
    e.preventDefault();
  
    try {
      const formData = new FormData(formRef.current);
  
      // Append checkbox values to formData
      Object.entries(checkboxValues).forEach(([key, value]) => {
        formData.append(key, value ? 'Yes' : 'No');
      });
  
      // Send form data to the server
      const response = await axios.post('https://gautamsolar.us/submit-contactus', Object.fromEntries(formData));
      
  
      console.log('Data before sending email:', Object.fromEntries(formData));
      console.log('Server response:', response.data);
  
      setShowModal(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  // const [checkboxValues, setCheckboxValues] = useState({
  //   moreThan500: false,
  //   from100To500: false,
  //   from10To100: false,
  //   from1To10: false,
  //   lessThan1: false,
  //   somethingElse: false,
  // });

  // const formRef = useRef();
  // const [showModal, setShowModal] = useState(false);

  // const handleCheckboxChange = (checkboxName) => {
  //   setCheckboxValues((prevValues) => ({
  //     ...prevValues,
  //     [checkboxName]: !prevValues[checkboxName],
  //   }));
  // };

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   const formData = new FormData(formRef.current);

  //   const checkboxElements = formRef.current.querySelectorAll('input[type="checkbox"]');
  //   checkboxElements.forEach((checkbox) => {
  //     formData.set(checkbox.name, checkbox.checked ? "on" : "off");
  //   });

  //   const selectedOptions = Array.from(checkboxElements)
  //     .filter((checkbox) => checkbox.checked)
  //     .map((checkbox) => {
  //       const label = formRef.current.querySelector(`label[for="${checkbox.id}"]`);
  //       return label ? label.innerText.trim() : "";
  //     });

  //   formData.set("mwp", selectedOptions.join(", "));

  //   const data = {};
  //   formData.forEach((value, key) => {
  //     data[key] = value;
  //   });

  //   console.log("Data before sending email:", data);

  //   emailjs
  //     .sendForm(
  //       "service_5z4ro5m",
  //       "template_zc7mznh",
  //       formRef.current,
  //       "3U521Xtgpbz_KPpKd"
  //     )
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         console.log("Message Success");
  //         setShowModal(true);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         console.log("Message not successful");
  //       }
  //     );
  // };

  return (
    <>
      <section
        className="elementor-section elementor-top-section elementor-element elementor-element-d29e141 elementor-section-full_width elementor-section-stretched elementor-section-height-default elementor-section-height-default"
        data-id="d29e141"
        data-element_type="section"
        data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;stretch_section&quot;:&quot;section-stretched&quot;}"
        style={{ ...sectionStyle }}
      >
        <div className="elementor-background-overlay"></div>
        <Container className="elementor-container elementor-column-gap-no">
          <Row className="elementor-row">
            <Col
              className="elementor-column elementor-col-12 elementor-top-column elementor-element elementor-element-0a1c64f"
              data-id="0a1c64f"
              data-element_type="column"
            >
              <div className="elementor-column-wrap elementor-element-populated">
                <div className="elementor-widget-wrap">
                  <div
                    className="elementor-element elementor-element-b12b60d elementor-widget elementor-widget-modina_section_title"
                    data-id="b12b60d"
                    data-element_type="widget"
                    data-widget_type="modina_section_title.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="block-contents text-center">
                        <div className="section-title">
                          <h5 className="text-center"></h5>
                          <span></span>
                          <h2>Contact Us</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Container fluid>
        <div className="bg-color my-5">
          <Form ref={formRef} onSubmit={sendEmail}>
            <Row className="mb-4">
              <Col md="6" lg="3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Name*"
                  name="name"
                  aria-label="Name"
                />
              </Col>
              <Col md="6" lg="3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Mobile No*"
                  name="phone"
                  aria-label="Mobile Number"
                />
              </Col>
              <Col md="6" lg="3">
                <Form.Control
                  required
                  type="text"
                  placeholder="Email*"
                  name="email"
                  aria-label="Email"
                />
              </Col>
              <Col md="6" lg="3">
                <Form.Control
                  required
                  type="text"
                  placeholder="City*"
                  name="city"
                  aria-label="City"
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" lg="8">
                <div className="border rounded d-flex py-3 bg-light">
                  <div className="">
                    <div className="box__label--rotate">
                      <h5>CONTACT US</h5>
                    </div>
                  </div>
                  <div className="p-2">
                    <div className="text-center cheak-boxHeading">
                      <h5>
                        <strong>How Many MWp Required?*</strong>
                      </h5>
                    </div>
                    <div className="row">
                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="More than 500 MWp"
                            onChange={() => handleCheckboxChange("moreThan500")}
                            id="moreThan500"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="100 MWp to 500 MWp"
                            onChange={() => handleCheckboxChange("100 MWp to 500 MWp")}
                            id="from100To500"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="10 MWp to 100 MWp"
                            onChange={() => handleCheckboxChange("10 MWp to 100 MWp")}
                            id="from10To100"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="1 MWp to 10 MWp"
                            onChange={() => handleCheckboxChange("1 MWp to 10 MWp")}
                            id="from1To10"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="Less than 1 MWp"
                            onChange={() => handleCheckboxChange("Less than 1 MWp")}
                            id="lessThan1"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>

                      <Col md="6">
                        <Form.Group className="mb-3">
                          <Form.Check
                            label="I want Something else"
                            onChange={() => handleCheckboxChange("I want Something else")}
                            id="somethingElse"
                            name="mwpCheckbox"
                          />
                        </Form.Group>
                      </Col>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md="12" lg="4">
                <div className="border rounded h-100 bg-light">
                  <div className="p-2">
                    <FloatingLabel controlId="floatingTextarea2" label="Remark*">
                      <Form.Control
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: "150px" }}
                        name="remark"
                        required
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="text-center p-4">
              <Button
                style={{ backgroundColor: "#a20000", color: "#ffffff" }}
                type="submit"
                variant="danger"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Form Submitted Successfully</Modal.Title>
          </Modal.Header>
          <Modal.Body>Your form has been submitted successfully!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="mb-5">
          <img src={getinTouch} alt="get" className="img-fluid" />
        </div>

        <div className="aboutSolar text-center">
          <h2>
            A brief about Gautam Solar
            <br />
            <span>
              <strong>Gautam Solar is one of India’s <br></br>Largest Solar Panel Manufacturer</strong>
            </span>
            <br />
            having following Product Lines:-
          </h2>
        </div>

        <div>
          <div className="mx-auto" style={{ maxWidth: "600px" }}>
            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "black" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  10 BB <span style={{ color: "#a20000" }}>Mono PERC 540-550 Wp</span>
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "black" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  16 BB <span style={{ color: "#a20000" }}>TOPCon 580-590 Wp</span>
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "#a20000" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  DCR <span style={{ color: "black" }}>Solar Panel</span>
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "#a20000" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  Bi-facial <span style={{ color: "black" }}>Mono PERC & TOPCon</span>
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "#a20000" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }}/>
                  </span>
                  4 <span style={{ color: "black" }}>Factories</span>
                </h3>
              </div>
              <div
                className="content text-start"
                style={{
                  textAlign: "start",
                  color: "black",
                  position: "relative",
                }}
              >
                <h3>Some Salient points about Gautam Solar</h3>
                <div
                  style={{
                    position: "absolute",
                    bottom: "-8px",
                    left: "72%",
                    transform: "translateX(-50%)",
                    borderBottom: "1px solid #a20000",
                    width: "150px", // Adjust the width of the line
                    borderRadius: "0 0 10px 10px", // Adjust the border-radius to control the curve
                  }}
                ></div>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "#a20000" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  BIS & ALMM <span style={{ color: "black" }}>Approved</span>
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content text-start">
                <h3 style={{ color: "black" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                  Certified as per <span style={{ color: "#a20000" }}>UL & IEC </span>Standard
                </h3>
              </div>
            </div>

            <div className="my-4">
              <div className=" content  text-start">
                <h3 style={{ color: "#a20000" }}>
                  <span>
                    <img src={tick} alt="tick" style={{ width: "45px" }} />
                  </span>
                   25+ years <span style={{ color: "black" }}>Solar Experience</span>
                </h3>
              </div>
            </div>

            <div className="aboutSolar text-center">
              <h2>To Know more about us</h2>
            </div>

            <div className="text-center p-4">
              <RouterLink to="/" target="_blank" rel="noopener noreferrer">
                <Button
                  style={{ backgroundColor: "#a20000", color: "#ffffff" }}
                  variant="danger"
                >
                  <img
                    src={sender}
                    alt="sender"
                    style={{ width: "12%", marginRight: "5px" }}
                    className="img-fluid"
                  />
                  <h4 style={{ fontSize: "14px" }}>Go to our Homepage</h4>
                </Button>
              </RouterLink>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactUS;
